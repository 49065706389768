// This file is deprecated in 1.12.0 to be removed in 1.14
( function() {
"use strict";

define('jquery/ui-modules/core', [
	"jquery",
	"./data",
	"./disable-selection",
	"./focusable",
	"./form",
	"./ie",
	"./keycode",
	"./labels",
	"./jquery-patch",
	"./plugin",
	"./safe-active-element",
	"./safe-blur",
	"./scroll-parent",
	"./tabbable",
	"./unique-id",
	"./version"
] );
} )();

